<template>
  <footer>
    <div class="container">
      <div class="row p-4 px-0">
        <!-- logo -->
        <div class="col-lg-4 col-md-6 text-center mt-1 mt-md-0">
          <a href="/" aria-label="ثقه العربية" title="ثقه العربية">
            <img
              src="../assets/images/header_logo.webp"
              alt="ثقه العربية"
              width="200"
              height="60"
              title="ثقه العربية"
              loading="lazy"
            />
          </a>
          <!-- روابط التواصل الاجتماعي -->
          <div class="text-center m-auto">
            <ul class="list-unstyled d-flex m-0 mt-2 gap-3 justify-content-center">
              <li>
                <a
                  :href="$store.state.generalSettings.facebook"
                  target="_blank"
                  aria-label="facebook"
                  class="text-white cursor-pointer"
                  title="facebook"
                >
                  <i class="bi bi-facebook fs-5"></i>
                </a>
              </li>
              <li>
                <a
                  :href="$store.state.generalSettings.twitter"
                  target="_blank"
                  aria-label="twitter"
                  class="text-white cursor-pointer"
                  title="twitter"
                >
                  <i class="bi bi-twitter fs-5"></i>
                </a>
              </li>
              <li>
                <a
                  :href="$store.state.generalSettings.instagram"
                  target="_blank"
                  aria-label="instagram"
                  class="text-white cursor-pointer"
                  title="instagram"
                >
                  <i class="bi bi-instagram fs-5"></i>
                </a>
              </li>
              <li>
                <a
                  :href="$store.state.generalSettings.snapchat"
                  target="_blank"
                  aria-label="snapchat"
                  class="text-white cursor-pointer"
                  title="snapchat"
                >
                  <i class="bi bi-snapchat fs-5"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- روابط مهمة (الشروط والأحكام + سياسة الخصوصية) -->
        <div class="col-lg-4 col-md-6 text-center mt-3 mt-md-0">
          <h4 class="fw-bold active_link">
            {{ $t("ImportantLinks") }}
          </h4>
          <ul class="list-unstyled d-flex m-0 flex-column gap-1">
            <li>
              <RouterLink
                to="/Subscribe"
                :aria-label="$t('Subscribe')"
                :title="$t('Subscribe')"
              >
                {{ $t("Subscribe") }}
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to="/terms-and-condition"
                :aria-label="$t('TermsAndConditions')"
                :title="$t('TermsAndConditions')"
              >
                {{ $t("TermsAndConditions") }}
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to="/privacy-policy"
                :aria-label="$t('PrivacyPolicy')"
                :title="$t('PrivacyPolicy')"
              >
                {{ $t("PrivacyPolicy") }}
              </RouterLink>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6 text-center mt-3 mt-md-0">
          <h4 class="fw-bold active_link">
            {{ $t("DownloadApp") }}
          </h4>
          <ul
            class="list-unstyled d-flex m-0 gap-1 m-auto justify-content-center align-items-center"
          >
            <li>
              <a
                :href="general_settings.google_play_link"
                target="_blank"
                aria-label="google play"
                class="cursor-pointer"
                :title="$t('GooglePlay')"
              >
                <img
                  src="../assets/images/google_play.webp"
                  alt="Google Play"
                  class="rounded"
                  width="150"
                  height="60"
                  loading="lazy"
                />
              </a>
            </li>
            <li>
              <a
                :href="general_settings.apple_store_link"
                target="_blank"
                aria-label="App Store"
                class="cursor-pointer"
                :title="$t('AppStore')"
              >
                <img
                  src="../assets/images/app_store.webp"
                  alt="App Store"
                  class="rounded"
                  width="140"
                  height="50"
                  loading="lazy"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12 text-center footer_two">
      <h6 class="m-0 py-2 text-light">
        {{ $t("AllRightsReserved") }}
        <strong>
          {{ $t("AppName") }}
        </strong>
        &copy; {{ new Date().getFullYear() }}
      </h6>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterHome",
  data() {
    return {
      general_settings: {},
    };
  },
  mounted() {
    // session storage get item general_settings
    if (sessionStorage.getItem("general_settings")) {
      this.general_settings = JSON.parse(sessionStorage.getItem("general_settings"));
    } else {
      this.$store.dispatch("fetchGeneralSettings");
    }
  },
};
</script>

<style lang="scss" scoped>
footer {
  background-color: var(--accent-300);

  a {
    color: var(--text-400);
    font-weight: 400;
  }

  .active_link {
    color: var(--accent-400);
    font-weight: 600;
  }
}

.footer_two {
  background-color: var(--accent-700);
}

.bi {
  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--accent-400);
  }
}
</style>
