<template>
  <main class="m-- bg-white pb-3">
    <img
      src="../assets/images/banner.webp"
      alt="بانر ثقه العربية"
      class="img-fluid w-100 wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.1s"
      title="بانر ثقه العربية"
      width="100%"
      height="100%"
      loading="lazy"
    />
    <TitleHome
      :title="$t('Choose a Plan')"
      :subTitle="$t('planDescription')"
      class="wow bounceInUp"
      data-wow-duration="1s"
      data-wow-delay="0.3s"
    />
    <div
      class="vh-100 d-flex justify-content-center align-items-center"
      v-if="isLoadingPlans"
    >
      <div class="spinner-grow spinner-grow-lg m-auto text-primary"></div>
    </div>
    <div v-else>
      <swiper
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="true"
        :slidesPerView="'auto'"
        :coverflowEffect="{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }"
        :initialSlide="1"
        :pagination="true"
        :modules="modules"
        lazy-preload-prev-next="true"
        lazy-preloader-class="swiper-lazy-preloader-white"
        lazy="true"
        class="mySwiper wow bounceInUp"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <swiper-slide v-for="(plan, index) in plans" :key="index">
          <div class="card rounded bg-light border-0 shadow-sm">
            <img
              :src="plan.thumbnail"
              class="cursor-pointer object-fit-cover"
              :alt="plan.name"
              height="200px"
              width="300px"
              :title="plan.name"
              @click="selectPlan(plan.slug)"
              loading="lazy"
            />
            <div class="card-body">
              <div
                class="d-flex text-center m-auto justify-content-center align-items-center mb-3"
              >
                <h5 class="fw-bold text-primary text-center m-0">
                  {{ plan.name }}
                </h5>
              </div>
              <div class="d-flex">
                <n-switch
                  v-model:value="plan.showDetails"
                  class="m-auto bg-white rounded-pill"
                  size="large"
                  aria-label="Show Details"
                  title="Show Details"
                >
                  <template #checked>
                    {{ $t("PlanDetails") }}
                  </template>
                  <template #unchecked>
                    {{ $t("showDetails") }}
                  </template>
                </n-switch>
              </div>
              <div class="d-flex gap-3 flex-wrap align-items-center">
                <n-collapse-transition :show="plan.showDetails">
                  <div class="d-flex flex-column">
                    <div v-for="(feature, fIndex) in plan.features" :key="fIndex">
                      <p>
                        <i
                          class="bi"
                          :class="
                            feature.status === 'active'
                              ? 'text-primary bi-check-circle-fill'
                              : 'text-muted bi-x-circle-fill'
                          "
                        ></i>
                        <span
                          class="mx-1"
                          :class="
                            feature.status === 'active'
                              ? 'text-primary'
                              : 'text-muted text-decoration-line-through'
                          "
                        >
                          {{ feature.name }}
                        </span>
                      </p>
                    </div>
                  </div>
                </n-collapse-transition>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="text-center mt-3 my-5" v-if="plans.length === 0">
        <p class="text-muted">
          <i class="bi bi-info-circle"></i>
          {{ $t("No plans found") }}
        </p>
      </div>
    </div>
    <AllPages />
    <hr class="mt-5" />
    <SuccessPartners />
  </main>
</template>

<script>
import s from "axios";
import { Swiper as t, SwiperSlide as e } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow as o, Pagination as i } from "swiper/modules";
import { NCollapseTransition as m, NSwitch as a } from "naive-ui";
import p from "@/components/Home/SuccessPartners.vue";
import r from "@/components/TitleHome.vue";
import { pageTitle as n } from "@/utils/pageTitle";
import l from "@/components/AllPages.vue";
export default {
  name: "HomeView",
  data: () => ({ plans: [], isLoadingPlans: !0 }),
  methods: {
    async getPlans() {
      await s.get("/plans").then((s) => {
        200 == s.status &&
          ((this.plans = s.data.data[0].map((s) => ({ ...s, showDetails: !1 }))),
          (this.isLoadingPlans = !1));
      });
    },
    selectPlan(s) {
      this.$router.push(`/plan/${s}`);
    },
  },
  components: {
    Swiper: t,
    SwiperSlide: e,
    NCollapseTransition: m,
    NSwitch: a,
    SuccessPartners: p,
    TitleHome: r,
    AllPages: l,
  },
  mounted() {
    this.getPlans(), n(this.$t("Home"));
  },
  setup: () => ({ modules: [o, i] }),
};
</script>

<style scoped lang="scss">
.swiper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  max-width: 350px;
  border-radius: 50px;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.m-- {
  margin-top: -140px;
}
.text-primary {
  color: var(--text-300) !important;
}
</style>
