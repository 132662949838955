<template>
  <div>
    <TitleHome
      :title="$t('Success Partners')"
      :subTitle="$t('Success Partners Description')"
      class="wow bounceInUp"
      data-wow-duration="1s"
      data-wow-delay="0.3s"
    />

    <!-- Loading spinner while partners are loading -->
    <div
      class="d-flex flex-column my-5 gap-2 justify-content-center align-items-center"
      v-if="isLoadingPartners"
    >
      <div class="spinner-grow spinner-grow-lg m-auto text-primary"></div>
      <h6 class="text-muted">{{ $t("Loading...") }}</h6>
    </div>

    <!-- Partners content -->
    <div v-else class="mt-4 wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.3s">
      <div class="container">
        <div class="row">
          <!-- Partners grid -->
          <div
            class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 d-flex justify-content-center align-items-center"
            v-for="(partner, index) in visiblePartners"
            :key="index"
          >
            <div class="d-flex flex-column text-center">
              <img
                :src="partner.thumbnail"
                class="rounded object-fit-contain"
                :alt="partner.name"
                height="150px"
                width="100%"
                :title="partner.name"
                loading="lazy"
              />
              <h6 class="mb-2 mt-2 ellipse_text">
                {{ partner.name }}
              </h6>
            </div>
          </div>

          <!-- Not found message -->
          <div class="text-center mt-3 my-5" v-if="partners.length === 0">
            <p class="text-muted">
              <i class="bi bi-info-circle"></i>
              {{ $t("No partners found") }}
            </p>
          </div>
        </div>

        <!-- Show More / Show Less buttons -->
        <div class="text-center my-2" v-if="partners.length > 10">
          <button
            v-if="!allPartnersVisible"
            @click="togglePartners"
            class="btn text-primary"
          >
            <i class="bi bi-chevron-down fs-5"></i>
            {{ $t("ShowMore") }}
          </button>
          <button v-else @click="togglePartners" class="btn text-primary">
            <i class="bi bi-chevron-up fs-5"></i>
            {{ $t("ShowLess") }}
          </button>
        </div>
      </div>
    </div>

    <!-- Subscribe section -->
    <div class="bg-primary py-5 mt-4 mb-0">
      <SubscribeSection />
    </div>
  </div>
</template>

<script>
import TitleHome from "../TitleHome.vue";
import axios from "axios";
import SubscribeSection from "./SubscribeSection.vue";

export default {
  name: "SuccessPartners",
  data() {
    return {
      partners: [], // Full list of partners
      visibleCount: 12, // Initially visible partners
      isLoadingPartners: true, // Loading state
      allPartnersVisible: false, // Toggle for showing more/less partners
    };
  },
  components: {
    TitleHome,
    SubscribeSection,
  },
  computed: {
    // Slice the list of partners based on visibleCount
    visiblePartners() {
      return this.partners.slice(0, this.visibleCount);
    },
  },
  methods: {
    // Fetch partners from the API
    async getPartners() {
      try {
        const response = await axios.get("/partners");
        if (response.status === 200) {
          this.partners = response.data.data[0];
        }
      } catch (error) {
        console.error("Error fetching partners:", error);
      } finally {
        this.isLoadingPartners = false;
      }
    },
    // Toggle between showing more and fewer partners
    togglePartners() {
      if (this.allPartnersVisible) {
        this.visibleCount = 12; // Reset to initial number of partners visible
      } else {
        this.visibleCount = this.partners.length; // Show all partners
      }
      this.allPartnersVisible = !this.allPartnersVisible;
    },
  },
  mounted() {
    // Fetch the partners when the component mounts
    this.getPartners();
  },
};
</script>

<style lang="scss" scoped>
.text-primary {
  color: var(--text-300) !important;
}

.bg-primary {
  background-color: var(--accent-300) !important;
}

.ellipse_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
